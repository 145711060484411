<template>
  <div class="teacherText">
    <main>
      <div class="swipe" v-if="isShow" v-show="allList.length">
        <div class="leftArrow">
          <img src="../../../assets/student/videoleft.png" @click="arrowClick('left')" />
        </div>
        <el-carousel @change="swipeChange" trigger="click" :autoplay="false" arrow="never" id="cardShow" ref="dropdownRef">
          <el-carousel-item v-for="item in allList" :key="item">
            <div class="swipeitem" v-for="(ele,index) in item" :key="index" @click="goLook(ele)">
              <div class="icon1" v-show="ele.xxzt == 2">学习结束</div>
              <div class="icon2" v-show="ele.xxzt == 1">正在学习</div>
              <div class="icon3" v-show="ele.xxzt == 0">未开始学习</div>
              <div class="icon4" v-show="ele.xxzt == 3"></div>
              <!-- <img v-if="ele.xxzt == 3" src="../../../assets/student/novideo.png" alt=""> -->
              <div v-if="ele.xxzt == 3" class="img imgbox">
                <img src="../../../assets/student/novideo.png" alt="">
              </div>
              <img class="img" v-else :src="imgarr[index]" alt="">
              <p>{{ele.kwmc}}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="rightArrow">
          <img src="../../../assets/student/videoright.png" @click="arrowClick('right')" />
        </div>
        <div class="tigger">
          <li @click="tiggerclick(index)" v-for="item,index in allList" :class="tiggerIndex == index ? 'liActive' : ''" :key="index"></li>
        </div>
      </div>
      <div class="zwnr" v-show="!allList.length">暂无内容</div>
      <p class="returnbtn" @click="upperLever"></p>
    </main>
  </div>
  <Header :header_data="header_data"/>
</template>
<script>
import Header from "../components/zjfheader.vue";
import { getTextVideoText } from '@/api/student'
import { nextTick, onMounted, reactive, ref, toRefs } from 'vue-demi'
import { getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus';
import img1 from '../../../assets/student/1.png'
import img2 from '../../../assets/student/2.png'
import img3 from '../../../assets/student/3.png'
import img4 from '../../../assets/student/4.png'
import img5 from '../../../assets/student/5.png'
import img6 from '../../../assets/student/6.png'
export default {
  components: {
    Header
  },
  setup() {
    const { appContext } = getCurrentInstance();
    // vue 实例
    const that = appContext.config.globalProperties;
    onMounted(() => {
      data.header_data = [
        { title: "图谱英语 — ", path: "newEnglish" },
        { title: that.$route.query.zmc + " — ", path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
        { title: that.$route.query.dymc, path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` },
      ]
      data.jxInfo = JSON.parse(sessionStorage.getItem('jxInfo'))
      data.isShow = false
      // for(let i = 0; i < 4; i++) {
      //   data.allList.push(['Lesson 01 A private conversation','Lesson 01 A private conversation','Lesson 01 A private conversation','Lesson 01 A private conversation','Lesson 01 A private conversation','Lesson 01 A private conversation'])
      // }
      nextTick(() => {
        data.isShow = true
      })
      getTextVideoText({
        xsid: ~~that.$store.state.userInfo.yhid,
        dyid: ~~that.$route.query.dyid,
        currentPage: 1,
        pageSize: 999
      }).then(res => {
        if (res.success) {
          data.isShow = false
          let arr = []
          res.result.list.forEach((ele, index) => {
            if (!arr[parseInt(index / 8)]) {
              arr[parseInt(index / 8)] = []
            }
            arr[parseInt(index / 8)].push(ele)
          })
          data.allList = arr
          nextTick(() => {
            data.isShow = true
          })
        } else {
          ElMessage({
            type: 'warning',
            success: res.resultDesc
          })
        }
      })
    })
    const data = reactive({
      allList: [],
      isShow: true,
      jxInfo: {},
      pages: {currentPage:1},
      tiggerIndex: 0,
      header_data: [],
      imgarr: [img1,img2,img3,img4,img5,img6]
    })
    const dropdownRef = ref(null)
    // 轮播change
    function swipeChange(val) {
      data.tiggerIndex = val
    }
    // 自定义轮播图切换
    const arrowClick = (val) => {
      if (val === "right") {
        if (data.pages.currentPage >= data.pages.total / 10) {
          data.pages.currentPage = 1;
        } else {
          ++data.pages.currentPage;
        }
        dropdownRef.value.next();
      } else {
        if (data.pages.currentPage <= 1) {
          data.pages.currentPage = Math.ceil(data.pages.total / 10);
        } else {
          --data.pages.currentPage;
        }
        dropdownRef.value.prev();
      }
    };
    // 自定义轮播指示器
    const tiggerclick = (val) => {
      dropdownRef.value.setActiveItem(val);
    }
    // 点击去视频列表
    const goLook = (val) => {
      sessionStorage.setItem('textInfo', JSON.stringify(val))
      that.$router.replace({ name: 'teacherVideo', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwmc: val.kwmc }})
    }
    // 返回上一级
    const upperLever = () => {
      that.$router.replace({ name: 'chapter', query: { dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid }})
    }
    return {
      ...toRefs(data),
      swipeChange,
      arrowClick,
      dropdownRef,
      tiggerclick,
      goLook,
      upperLever
    }
  },
}
</script>
<style lang="scss" scoped>
.teacherText {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/zjfstudyBanner.png") no-repeat;
  background-size: 100% 100%;
  main {
    width: 94.166666666vw;
    height: 42vw;
    background: url("../../../assets/student/wordbanner2.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    .swipe {
      margin: auto;
      position: absolute;
      top: 3vw;
      left: 50%;
      bottom: 5vw;
      transform: translateX(-50%);
      box-sizing: border-box;
      width: calc(100% - 18vw);
      .rightArrow {
        position: absolute;
        right: -5vw;
        top: 50%;
        transform: translateY(-50%);
        width: 3.64583vw;
        height: 3.64583vw;
        cursor: pointer;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .leftArrow {
        position: absolute;
        left: -5vw;
        top: 50%;
        transform: translateY(-50%);
        width: 3.64583vw;
        height: 3.64583vw;
        cursor: pointer;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .tigger {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -8%;
        li {
          float: left;
          margin-right: 13px;
          padding: 4px 10px;
          background: #B6E0FD;
          border-radius: 4px;
          cursor: pointer;
        }
        .liActive {
          background: #2CA2F5;
        }
      }
    }
    .swipeitem {
      float: left;
      margin-bottom: 1.2vw;
      margin-right: 1.5625vw;
      width: 24.212157vw;
      height: 16.12916vw;
      background: #2CA2F5;
      border-radius: 20px;
      position: relative;
      cursor: pointer;
      .img {
        display: block;
        width: 97%;
        height: 83.6%;
        margin: 1% auto 0;
        border-radius: 20px;
        img {
          display: block;
          height: 60%;
          position: absolute;
          top: 46%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .imgbox {
        background: #fff;
      }
      p {
        font-size: 1.26vw;
        color: #fff;
        text-align: center;
        font-weight: 600;
        line-height: 2.2vw;
      }
      .icon1 {
        width: 7.0833vw;
        height: 3.59375vw;
        background: url('../../../assets/student/leveljieshu.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -.5vw;
        left: -.3vw;
        font-size: 1.0416vw;
        color: #fff;
        line-height: 2.9vw;
      }
      .icon2 {
        width: 7.0833vw;
        height: 3.59375vw;
        background: url('../../../assets/student/levelzhengz.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -.5vw;
        left: -.3vw;
        font-size: 1.0416vw;
        color: #fff;
        line-height: 2.9vw;
      }
      .icon3 {
        width: 7.0833vw;
        height: 3.59375vw;
        background: url('../../../assets/student/levelwks.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -.5vw;
        left: -.3vw;
        font-size: 1.0416vw;
        color: #fff;
        line-height: 2.9vw;
      }
      .icon4 {
        font-size: 2vw;
        font-weight: 600;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
        color: #333;
        font-weight: 600;
      }
    }
    .swipeitem:nth-child(3) {
      margin-right: 0;
    }
    .swipeitem:nth-child(6) {
      margin-right: 0;
    }
  }
  .returnbtn {
    width: 3.645833vw;
    height: 3.645833vw;
    background: url("../../../assets/student/wordreturn.png") no-repeat;
    background-size: 100% 100%;
    position: fixed;
    left: 2vw;
    bottom: 2vw;
    z-index: 2;
    cursor: pointer;
  }
  /deep/.el-carousel--horizontal {
    height: 100%;
    width: 100%;
  }
  /deep/.el-carousel__container {
    width: 100%;
    height: 100%;
  }
  /deep/.el-carousel__item.is-animating {
    width: 100%;
    height: 100%;
    padding-top: 0.46875vw;
    padding-left: 0.36458333333333334vw;
  }
  /deep/.el-carousel__indicators--horizontal {
    display: none;
  }
}
.zwnr {
  font-size: 30px;
  color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>